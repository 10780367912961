import React, { Component } from 'react';
import Header from '../../components/header/header';
import goodIcon from '../../images/good-icon.png';
import betterIcon from '../../images/better-icon.png';
import bestIcon from '../../images/best-icon.png';
import Footer from '../../components/footer/Footer';
import {Link} from 'react-router-dom';
import './Coverage.css';

class Coverage extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (  
            <div>
                <Header></Header>
                <section id="coverage-type">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group" id="adv-search">
                                    <div class="input-group-btn">
                                        <div class="btn-group" role="group">
                                            <button type="button" class="btn btn-primary num-btn">$65.05</button>
                                            <div class="dropdown dropdown-lg">
                                                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                    <span style={{color:"#231f20"}}>/ 12</span> <span class="caret" style={{color:"#eb2630"}}></span> 
                                                    <span style={{color: "#eb2630", paddingLeft: "40px"}} >month</span>
                                                </button> 
                                                <div class="dropdown-menu dropdown-menu-right" role="menu" style={{ width: "100%" }}>
                                                    <form class="form-horizontal" role="form">
                                                        <div class="form-group">
                                                            <label for="contain">11</label>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="contain">10</label>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="contain">09</label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary num-btn">$1936</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1>Coverage Types</h1>
                            </div>
                        </div>
                        <div class="row car-row">
                            <div class="col-md-4 text-center">
                                <a href="#">
                                <div class="car-sec">
                                    <img src={ goodIcon } class="img-responsive"/>
                                </div>
                                </a>
                            </div>
                            <div class="col-md-4 text-center">
                                <a href="#">
                                <div class="car-sec">
                                    <img src={ betterIcon } class="img-responsive"/>
                                </div>
                                </a>
                            </div>
                            <div class="col-md-4 text-center">
                                <a href="#">
                                <div class="car-sec">
                                    <img src={bestIcon} class="img-responsive"/>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <hr/>
                        </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <h1>Coverage Time and Mileage (Term)</h1>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <ul>
                            <li><a href="#">1 year <br/>15,000 Miles</a></li>
                            <li><a href="#">2 year <br/>30,000 Miles</a></li>
                            <li><a href="#">3 year <br/>45,000 Miles</a></li>
                            <li><a href="#">4 year <br/>60,000 Miles</a></li>
                            <li><a href="#">5 year <br/>75,000 Miles</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <hr/>
                        </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <h1>Deductible (Out of pocket)</h1>
                        </div>
                        </div>
                        <div class="row car-row">
                        <div class="col-md-3"></div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-default number-btn">$250</button>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-default number-btn">$500</button>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-default number-btn">$20/80</button>
                        </div>
                        <div class="col-md-3"></div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <hr/>
                        </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                        <div class="col-md-12 text-center">
                            <Link type="button" class="btn btn-default check-btn" to='/checkout'> Checkout </Link>
                        </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        );
    }
}
 
export default Coverage;
