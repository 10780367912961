import React, { Component } from 'react';
import './Checkout.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/header';
import ManIcon from '../../images/man-icon1.png';
import {MultiStep} from '../../components/checkout/MultiStep';
 
class CheckOut extends Component {
    componentWillMount(){
        console.log('will mount');
    }
    
    render() {
        return (
            <div>
                <Header></Header>
                <section id="form-step1">
                    <div class="container">
                        <MultiStep></MultiStep>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        );
    }
}

export default CheckOut
