import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import './Stepbutton.css';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

export const StepButton = props => {
  const { step } = props;
  const classes = useStyles();
  switch (step) {
    case 1:
      return (
        <>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 text-center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="btn btn-default next-btn">
                Next
              </Button>
            </div>
            <div class="col-md-3"></div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 text-center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="btn btn-default next-btn">
                Next
              </Button>
            </div>
            <div class="col-md-3"></div>
          </div>
        </>
      );
      case 3:
        return (
          <>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 text-center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="btn btn-default next-btn">
                Submit
              </Button>
            </div>
            <div class="col-md-3"></div>
          </div>
          </>
        );
    default:
      return <></>;
  }
};