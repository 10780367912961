import React from 'react';
import {BrowserRouter as Router,Route,} from 'react-router-dom';
import Home from '../pages/home';
import Coverage from '../pages/coverage/Coverage';
import Checkout from '../pages/checkout/checkout';

const Web = () => (
    <Router>
        <Route exact path='/' component={Home} />
        <Route exact path='/coverage' component={Coverage} />
        <Route exact path='/checkout' component={Checkout} />
    </Router>
)

export default Web
