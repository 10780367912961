import React, { Component } from 'react';
import './header.css';
import Logo2 from '../../images/logo2.png';
import {Link} from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <section id="coverage-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <Link to="/"><img src={Logo2} class="img-responsive logo"/></Link>
                        </div>
                    </div>
                </div>
            </section>   
        );
    }
}
 
export default Header;