import React from "react";
import { Field } from "formik";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
import ManIcon from '../../../images/man-icon1.png';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));

// var styles = {
//     textFld: { width: 275}   //assign the width as your requirement
// };

export const FormFirstStep = formikProps => {
  const { errors, touched } = formikProps;
  return (
    <>
      <div className="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <img src={ManIcon} class="img-responsive text"/>
        <h1>Almost there</h1>
        <p>Lets get your registered with an account <br/>and you can also keep track of your coverage and <br/>contract on your Fuccillo app</p>
        
          <div class="form-group">
            {/* <input type="email" className="form-control"  placeholder="VIN"/> */}
            <Field
            style={{marginTop:"10px"}}
            placeholder="VIN*"
            className={`form-control ${errors.vin?'error-class-form':''}`}
            name="vin" />
          </div>
          <div class="form-group">
          
          <Field
            style={{marginTop:"10px"}}
            placeholder="First Name*"
            className={`form-control ${errors.firstName?'error-class-form':''}`}
            name="firstName"
          />
          </div>
          <div class="form-group">
            <Field
            style={{marginTop:"10px"}}
            placeholder="Last Name*"
            className={`form-control ${errors.lastName?'error-class-form':''}`}
            name="lastName"
          />
          </div>
          <div class="form-group">
            {/* <input type="email" className="form-control"  placeholder="Email"/> */}
            <Field
            style={{marginTop:"10px"}}
            placeholder="Email*"
            className={`form-control ${errors.email?'error-class-form':''}`}
            name="email" />
          </div>
          <div class="form-group">
            {/* <input type="email" className="form-control"  placeholder="Phone"/> */}
            <Field
            style={{marginTop:"10px"}}
            placeholder="Phone*"
            className={`form-control ${errors.phone?'error-class-form':''}`}
            name="phone" />
          </div>
        
      </div>
      <div class="col-md-3"></div>
      </div>
    </>
  );
};
