import React, { useState } from "react";
import { Formik, Form } from "formik";


import { FormFirstStep } from "../../components/checkout/forms/FormFirstStep";
import { FormSecondStep } from "../../components/checkout/forms/FormSecondStep";
import { FormThirdStep } from "../../components/checkout/forms/FormThirdStep";
import { FormSuccess } from "../../components/checkout/forms/FormSuccess";
import { StepButton } from "../../components/checkout/stepbutton/Stepbutton";
//import { Header } from "./Header";
//import { StepButton } from "./StepButton";
import { makeStyles } from "@material-ui/core/styles";
import StepDots from "../../components/checkout/stepdots/Stepdots";

const useStyles = makeStyles(theme => ({
  form: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    
  }
}));

const renderStep = (step, values, errors, touched) => {
  switch (step) {
    case 1:
      return <FormFirstStep errors={errors} touched={touched} />;
      
    case 2:
      return <FormSecondStep errors={errors} touched={touched} />;
    
    case 3:
        return <FormThirdStep errors={errors} touched={touched} />;
    
    case 4:
      return <FormSuccess values={values} />;
    
    default:
      return <FormFirstStep errors={errors} touched={touched} />;
  }
};

export const MultiStep = () => {
  const [step, setStep] = useState(1);
  const classes = useStyles();
  const formData = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    city: "",
    state: "",
    country: ""
  };
  const handleSubmit = () => setStep(step => step + 1);

  const validate = values => {
    console.log(values);
    console.log(step);
    const errors = {};
    if(step==1){
      if (!values.firstName) {
        errors.firstName = "Required";
      }
  
      if (!values.lastName) {
        errors.lastName = "Required";
      }
      if (!values.vin) {
        errors.vin = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.phone) {
        errors.phone = "Required";
      }
    }
    if(step==3){
      if (!values.address) {
        errors.address = "Required";
      }
  
      if (!values.city) {
        errors.city = "Required";
      }
      // if (!values.state) {
      //   errors.state = "Required";
      // }
      if (!values.zip) {
        errors.zip = "Required";
      }
      if (!values.signature) {
        errors.signature = "Required";
      }
      if (!values.cardNumber) {
        errors.cardNumber = "Required";
      }
  
      if (!values.mm) {
        errors.mm = "Required";
      }
      if (!values.yy) {
        errors.yy = "Required";
      }
      if (!values.cvc) {
        errors.cvc = "Required";
      }
      if (!values.signature) {
        errors.signature = "Required";
      }
    }

    

    console.log(errors);  
    return errors;
  };
  return (
    <>
      
      <Formik
        enableReinitialize
        initialValues={{ ...formData }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors, touched }) => (
          <Form className={classes.form}>
            {renderStep(step, values, errors, touched)}
            <StepButton step={step} />
            <StepDots step={step}></StepDots>
            
            
          </Form>
        )}
      </Formik>
    </>
  );
};