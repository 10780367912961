import './App.css';
import Web from './routes/Web';

function App() {
  return (
    <Web/>
  );
}

export default App;
