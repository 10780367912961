import React from "react";
import { Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ManIcon from '../../../images/man-icon1.png';

export const FormSecondStep = formikProps => {
  const { errors, touched } = formikProps;
  return (
    <>
      <div className="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <img src={ManIcon} class="img-responsive text"/>
        <h1>Almost there</h1>
        <p>Lets get your registered with an account <br/>and you can also keep track of your coverage and <br/>contract on your Fuccillo app</p>
        <h4>Fuccillo Protection is fueld by our commitment to honesty</h4>
        <div class="row">
          <div class="col-xs-2 col-md-3">
            <i class="fa fa-circle" aria-hidden="true"></i>
          </div>
          <div class="col-xs-10 col-md-9 text-left">
            <p style={{color:"#eb2630", fontSize: "18px" }}><b>My vehicle is free of previous defects.</b></p>
          </div>
          <div class="col-md-12" style={{marginTop: "30px"}}></div>
            <div class="col-xs-2 col-md-3">
              <i class="fa fa-circle" aria-hidden="true"></i>
            </div>
              <div class="col-xs-10 col-md-9 text-left">
              <p>I understand that I have to wait 1,000 miles and 30 days before filing a claim becomes valid.</p>
            </div>
            <div class="col-md-12" style={{marginTop: "30px"}}></div>
            <div class="col-xs-2 col-md-3">
              <i class="fa fa-circle" aria-hidden="true"></i>
            </div>
            <div class="col-xs-10 col-md-9 text-left">
              <p>I understand that if I stop making payments on my contract, all coverage becomes void and may result in cancellation of this contract; no refund will be due and no claims will be authorized.</p>
            </div>
            <div class="col-md-12" style={{marginTop: "30px"}}></div>
            <div class="col-xs-2 col-md-3">
              <i class="fa fa-circle" aria-hidden="true" style={{color: "#fff", border: "1px solid #231f20", width: "28px", height: "28px", borderRadius: "200px",}}></i>
            </div>
            <div class="col-xs-10 col-md-9 text-left">
              <p>I understand that if my odometer entry is not valid, my contract becomes void (will be validated at time of service).</p>
            </div>
            <div class="col-md-12" style={{marginTop: "30px"}}></div>
            <div class="col-md-3"></div>
              
          <div class="col-md-9 text-left">
            <form class="pic-upload-form">
              <input type="file" name="file-1[]" id="file-1" class="inputfile inputfile-1" data-multiple-caption="{count} files selected" multiple="" />
                <label for="file-1"><span>Upload Odometer pic</span></label>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
