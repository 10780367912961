import React from "react";
import { Field } from "formik";
import TextField from "@material-ui/core/TextField";
import ManIcon from '../../../images/man-icon1.png';

export const FormThirdStep = formikProps => {
  const { errors, touched } = formikProps;
  return (
    <>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">
            <img src={ManIcon} class="img-responsive text"/>
            <h1>Last Step</h1>
          </div>
          <div class="col-md-3"></div>
        </div>
        
        <div class="row">
          <form class="last-form">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <p style={{display:"flow-root", fontSize:"20px", fontWeight:"bold"}}><span class="pull-left">Billing Address</span> <span class="pull-right"><i class="fa fa-address-book-o" aria-hidden="true" style={{color: "#231f20"}}></i></span></p>
              <div style={{border: "1px solid #231f20", display: "inline-block", width:"100%"}}>
                <div class="form-group col-md-12">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Adress"/> */}
                  <Field
                  placeholder="Address*"
                  className={`form-control ${errors.address?'error-class-form':''}`}
                  name="address" />
                </div>
                <div class="form-group col-md-12">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="City"/> */}
                  <Field
                  placeholder="City*"
                  className={`form-control ${errors.city?'error-class-form':''}`}
                  name="city" />
                </div>
                <div class="form-group col-md-12">
                  <select class="form-control" name="state" className={`form-control ${errors.state?'error-class-form':''}`}>
                    <option>State</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Zip"/> */}
                  <Field
                  placeholder="Zip*"
                  className={`form-control ${errors.zip?'error-class-form':''}`}
                  name="zip" />
                </div>
              </div>
            </div>
            {/*  */}
            <div class="col-md-6 payment-col">
              <p style={{display:"flow-root", fontSize: "20px",fontWeight: "bold"}} ><span class="pull-left">Payment Info</span> <span class="pull-right"><i class="fa fa-credit-card-alt" aria-hidden="true" style={{color: "#231f20"}}></i></span></p>
              <div style={{border:"1px solid #231f20", display: "inline-block", width: "100%"}}>
                <div class="form-group col-md-12">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Valid Card Number"/> */}
                  <Field
                  placeholder="Valid card Number*"
                  className={`form-control ${errors.cardNumber?'error-class-form':''}`}
                  name="cardNumber" />
                </div>
                <div class="col-md-12"></div>
                <div class="form-group col-md-2">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="MM"/> */}
                  <Field
                  placeholder="MM*"
                  className={`form-control ${errors.mm?'error-class-form':''}`}
                  name="mm" />
                </div>
                <div class="form-group col-md-2">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="YY"/> */}
                  <Field
                  placeholder="YY*"
                  className={`form-control ${errors.yy?'error-class-form':''}`}
                  name="yy" />
                </div>
                <div class="form-group col-md-6">
                  
                </div>
                <div class="form-group col-md-2">
                  {/* <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="CVC"/> */}
                  <Field
                  placeholder="CVC*"
                  className={`form-control ${errors.cvc?'error-class-form':''}`}
                  name="cvc" />
                </div>
                <div class="col-md-12"></div>
                <div class="form-group col-md-12">
                  {/* <input style={{height: "126px"}} type="email" class="form-control" id="exampleFormControlInput1" placeholder="Your Signature"/> */}
                  <Field
                  style={{height: "126px"}}
                  placeholder="Your Signature*"
                  className={`form-control ${errors.signature?'error-class-form':''}`}
                  name="signature" />
                </div>
              </div>
            </div>
            {/*  */}
            <div class="col-md-1"></div>
            <div class="col-md-12" style={{marginTop: "50px"}}></div>
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="chiller_cb">
                <input id="myCheckbox1" type="checkbox" />
                <label for="myCheckbox1"> I agree to the VSC terms and condition</label>
                <span></span>
              </div>
              <div class="chiller_cb">
                <input id="myCheckbox2" type="checkbox"/>
                <label for="myCheckbox2"> I agree and have read the Payment Installment agreement</label>
                <span></span>
              </div>
            </div>
            <div class="col-md-3"></div>
          </form>
        </div>
    </>
  );
};
