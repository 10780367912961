import React, { Component } from 'react';
import Footer from '../components/footer/Footer';
import Landing from '../components/landing/Landing';
import Services from '../components/services/Services';

class Home extends Component {
    componentWillMount(){
        console.log('will mount');
    }
    
    render() {
        return (
            <div>
                <Landing></Landing>
                <Services></Services>
                <Footer></Footer>
            </div>
        );
    }
}

export default Home
