import React, { Component } from 'react';
import './Landing.css';
import Logo from '../../images/logo.png';
import MobileLogo from '../../images/mobile-logo.png';
import Car from '../../images/car-icon.png';
import TextImage from '../../images/text-img.png';
import {Link} from 'react-router-dom';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <section id="home-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                        <Link to="/"><img src={Logo} class="img-responsive logo"/><img src={MobileLogo} class="img-responsive mobile-logo"/></Link>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-6">
                            <img src={Car} class="img-responsive car"/>
                            <img src={TextImage} class="img-responsive text"/>
                            <button type="button" class="btn btn-default available-btn">Financing Available!</button>
                        </div>
                    <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                    
                    <div class="col-md-12" style={{ padding: "0px" }}>
                        <div class="go-form">
                        <form>
                            <div class="field-sec">
                            <div class="form-group">
                                <select class="form-control">
                                <option>Vehicle Make</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                </select>
                            </div>
                            </div>
                            <div class="field-sec">
                            <div class="form-group">
                                <select class="form-control">
                                <option>Year</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                </select>
                            </div>
                            </div>
                            <div class="field-sec">
                            <div class="form-group">
                                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Odometer"/>
                            </div>
                            </div>
                            <div class="field-sec btn-sec">
                                <Link type="button" class="btn btn-default go-btn" to='/coverage'> Go </Link>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </section> 
        );
    }
}
 
export default Landing;