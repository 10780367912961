import React, { Component } from 'react';

class StepDots extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const step = this.props.step;
        return ( 
            
        step<4?
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">
            <ul>
              <li><a><i class="fa fa-circle" aria-hidden="true" style={{color: step==1?"#eb2630":"#231f20"}}></i></a></li>
              <li><a><i class="fa fa-circle" aria-hidden="true" style={{color: step==2?"#eb2630":"#231f20"}}></i></a></li>
              <li><a><i class="fa fa-circle" aria-hidden="true" style={{color: step==3?"#eb2630":"#231f20"}}></i></a></li>
            </ul>
          </div>
          <div class="col-md-3"></div>
        </div>:""
      

        );
    }
}
 
export default StepDots;