import React, { Component } from 'react';
import './Services.css';
import CarOne from '../../images/car-icon1.png';
import CarTwo from '../../images/car-icon2.png';
import CarThree from '../../images/car-icon3.png';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <section id="services">
                <div class="container">
                    <div class="row">
                    <div class="col-md-4 text-center">
                        <a href="#">
                        <div class="car-sec">
                            <img src={ CarOne } class="img-responsive"/>
                            <h3>BENEFITS</h3>
                        </div>
                        </a>
                    </div>
                    <div class="col-md-4 text-center">
                        <a href="#">
                        <div class="car-sec">
                            <img src={ CarTwo } class="img-responsive"/>
                            <h3>INSPECTION</h3>
                        </div>
                        </a>
                    </div>
                    <div class="col-md-4 text-center">
                        <a href="#">
                        <div class="car-sec">
                            <img src={ CarThree } class="img-responsive"/>
                            <h3>FAQs</h3>
                        </div>
                        </a>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Services;