import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (  
            <section id="footer">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <p>Fuccillo.com All Rights Reserved Copy Right 2020</p>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Footer;