import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core/';
import ManIcon2 from '../../../images/man-icon2.png';
import Flower from '../../../images/flower.png';

export const FormSuccess = (formikProps) => {
  const { firstName, lastName, email, middleName, city, state } = formikProps.values;
  return (
    <>
      <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">
            <img src={ManIcon2} class="img-responsive text"/>
            <h1>Congratulations!</h1>
            <p>Take comfort in knowing that your vehicle is covered from expensive out of pocket repair costs now with your Fuccillo protection policy </p>
            <img src={Flower} class="img-responsive" style={{margin: "60px auto"}} />
            <p>You will also receive a conrmation email with your covereage info as well as a link to download the fuccillo app to have your information at your ngertips. AND KAZMI IS THE MODERFUCKIN BOMB!</p>
          </div>
          <div class="col-md-3"></div>
        </div>
    </>
  );
};